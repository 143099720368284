<template>
  <div>
    <page-header icon="fa fa-newspaper" :title="title" :links="getLinks()"></page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div ref="printMe" v-if="!isLoading(loadingName)">
      <div class="individuals-list" ref="excelMe">
        <x-table
          :columns="columns"
          :items="items"
          :show-counter="true"
          verbose-name="inscrit"
          @selectionChanged="onSelectionChanged($event)"
        ></x-table>
      </div>
    </div>
    <confirm-modal
      icon="fas fa-share"
      name="unsubscribe-newsletter"
      title="Désinscrire à la newsletter"
      :text="getUnsubscribeMessage()"
      @confirmed="confirmUnsubscribe()"
    >
    </confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import XTable from '@/components/Controls/Table/Table.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'
import { BackendMixin } from '@/mixins/backend'
import { makeIndividual } from '@/types/people'
import { BackendApi, openDocument } from '@/utils/http'
import ConfirmModal from '@/components/Modals/ConfirmModal.vue'

export default {
  name: 'NewsletterIndividuals',
  mixins: [BackendMixin],
  components: {
    ConfirmModal,
    PageHeader,
    LoadingGif,
    XTable,
  },
  props: {
  },
  data() {
    return {
      loadingName: 'newsletter-individuals',
      individuals: [],
      columns: [],
      selection: [],
    }
  },
  mounted() {
    this.columns = this.getColumns()
    this.loadData()
  },
  computed: {
    title() {
      return 'Inscrits à la newsletter'
    },
    items() {
      return this.individuals.map(
        elt => this.makeItem(elt)
      )
    },
  },
  methods: {
    ...mapActions(['addSuccess', 'addError', 'addWarning']),
    ...mapMutations(['startLoading', 'endLoading']),
    getColumns() {
      return [
        { selector: true, 'name': 'selector', },
        {
          name: 'lastName',
          label: 'Nom',
          onClick: item => {
            this.showIndividualSidebar(item.individual)
          },
          isLink: item => {
            return (item.individual)
          },
        },
        {
          name: 'firstName',
          label: 'Prénom',
          onClick: item => {
            this.showIndividualSidebar(item.individual)
          },
          isLink: item => {
            return (item.individual)
          },
        },
        { name: 'email', label: 'Email', displayAs: 'email', },
        {
          name: 'newsletterOn',
          label: 'Date d\'inscription',
          dateFormat: 'DD/MM/YYYY',
        }
      ]
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'liste-individus'
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinks() {
      const isDisabled = this.isLoading(this.loadingName) && !this.selection.length
      const links = [
        {
          id: 1,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'far fa-file-excel',
          cssClass: isDisabled ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
      if (this.hasPerm('people.change_individual')) {
        links.push(
          {
            id: 2,
            label: 'Désinscrire',
            callback: this.unsubscribe,
            icon: 'fas fa-share',
            cssClass: isDisabled ? 'btn-secondary disabled' : 'btn-secondary',
          }
        )
      }
      return links
    },
    makeItem(elt) {
      let entityId = 0
      if (elt.entities.length) {
        entityId = elt.entities[0].id
      }
      return {
        id: elt.id,
        fullName: elt.fullName(),
        lastName: elt.lastName,
        firstName: elt.firstName,
        email: elt.email,
        cellPhone: elt.cellPhone,
        individualId: elt.id,
        newsletterOn: elt.isNewsletterOn,
        entityId: entityId,
        individual: elt,
      }
    },
    async loadData() {
      let url = '/api/people/newsletter-individuals/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.individuals = resp.data.map(elt => makeIndividual(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onSelectionChanged(event) {
      this.selection = event.items
    },
    getUnsubscribeMessage() {
      if (this.selection.length === 1) {
        return 'Voulez-vous désinscrire ' + this.selection[0].fullName + '?'
      } else {
        return 'Voulez-vous désinscrire les ' + this.selection.length + ' inscrits sélectionnés?'
      }
    },
    unsubscribe() {
      this.$bvModal.show('bv-confirm-modal:unsubscribe-newsletter')
    },
    async confirmUnsubscribe() {
      let url = '/api/people/newsletter-individuals/'
      const data = {
        unsubscribe: this.selection.map(elt => elt.id),
      }
      const backendApi = new BackendApi('post', url)
      try {
        const resp = await backendApi.callApi(data)
        await this.loadData()
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style scoped>
</style>
